import React, { useState, useEffect } from "react";
import styles from "../C20_Faq/C20_Faq.module.css";
import { getC20FaqListsData } from "../../api/getData";
import { COMPONENT_C20_FAQ_COMPONENT } from "../../constants/componentTypes";
import Faq from './Faq';


export default function C20_FaqLists({ componentID, resourceVersionId, currentCountryCode }) {
  const [C20FaqListsData, setC20FaqListsData] = useState(null);

  useEffect(() => {
    getC20FaqListsData(COMPONENT_C20_FAQ_COMPONENT, componentID, setC20FaqListsData, resourceVersionId, currentCountryCode);
  }, [componentID, currentCountryCode, resourceVersionId]);

  return (
    <>
      {
        C20FaqListsData && (
          <>
            <div className={`${styles.temp_white_block} ${styles.faq_wrapper_box}`}>
              {C20FaqListsData?.attributes?.field_title && (
                <div className={`${styles.faq_all_title}`}>{C20FaqListsData.attributes.field_title}</div>
              )}
              <div className={`${styles.faq_pading}`}>
                <div className={`${styles.faq_detail}`}>
                  {C20FaqListsData.relationships.field_faq_lists &&
                    C20FaqListsData.relationships.field_faq_lists.data.map((data, index) => (
                      <Faq componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                    ))}
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};
