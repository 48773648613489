import React, { useState, useEffect } from "react";
import styles from "../C20_Faq/C20_Faq.module.css";
import { getC20FaqListsData } from "../../api/getData";
import { COMPONENT_C20_FAQ_COMPONENT } from "../../constants/componentTypes";
import { replaceTextLanguage, replaceTextGreenArrowIcon } from '../../utils/textUtils';

export default function Faq({ componentID, resourceVersionId, currentCountryCode }) {
    const [C20FaqListsData, setC20FaqListsData] = useState(null);

    useEffect(() => {
        getC20FaqListsData(COMPONENT_C20_FAQ_COMPONENT, componentID, setC20FaqListsData, resourceVersionId, currentCountryCode);
    }, [componentID, currentCountryCode, resourceVersionId]);
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            {
                C20FaqListsData?.attributes && (
                    <>
                        <dl className={`${styles.faq_box}`}>
                            <dt className={`${styles.faq_title} ${isOpen ? styles.openTitle : styles.closeTitle}`} onClick={handleClick}>
                                <div className={`${styles.big_title}`}>{C20FaqListsData?.attributes?.field_title}</div>
                            </dt>
                            <dd className={`${styles.faq_content} ${isOpen ? styles.display : styles.hidden}`} >
                                <div dangerouslySetInnerHTML={{
                                    __html:
                                        replaceTextGreenArrowIcon(
                                            replaceTextLanguage(currentCountryCode, C20FaqListsData?.attributes?.field_description?.value, 'html')
                                        ),
                                }} />
                            </dd>
                        </dl>
                    </>
                )
            }
        </>
    );
};
