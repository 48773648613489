import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import MenuItems from "../themeComponents/MegaMenu/MenuItems";
import TopBar from "../TopBar/TopBar";
import { getReviewSummary, getReviewSchemaInfo } from "../../api/getData";
import axios from 'axios'
import APIS from '../../config/api'
const Menu = () => {

  const location = useLocation();
  const page = location.pathname;
  const [serviceType, setServiceType] = useState("");
  const [areaServed, setAreaServed] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [ReviewSummary, setReviewSummary] = useState(null);
  const searchInputRef = useRef(null);
  const hiddenClick = () => {
    if (searchActive) {
      setSearchActive(false);
    }
  };
  const handleSearchClick = () => {
    const searchTextElement = document.getElementById('SearchText');
    const searchText = searchTextElement.value;
    const url = `/search-result?searchText=${encodeURIComponent(searchText)}`;
    hiddenClick();
    window.location.href = url;
    //navigate(url);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  //Stars HTML
  function renderStars(Summary, Average) {
    let starHTML = "";
    let proportion = 2.5 + Math.floor(Average) * 15 + (Average - Math.floor(Average)) * 10
    if (Math.floor(Average) === 5) {
      proportion = 72.5;
    }
    const DisplayWidth = (proportion).toFixed(2);

    starHTML += '<div class="star-content-box" >';
    starHTML += '<div class="star-emt-box" style="display: flex;gap: 3px;">';
    for (let i = 0; i < 5; i++) {
      starHTML += '<i class="bi bi-star"></i>';
    }
    starHTML += '</div>';
    // starHTML += '<div class="stat-fill-box" style="width: ' + DisplayWidth + 'px;">';
    starHTML += '<div class="stat-fill-box" style="display: flex;gap: 3px;">';
    for (let i = 0; i < 5; i++) {
      starHTML += '<i class="bi bi-star-fill"></i>';
    }
    starHTML += '</div>';
    starHTML += '</div>';

    starHTML += '<span>' + Average + '</span> &nbsp; (' + Summary + ' Reviews)';
    return <div >
      <div className="start-sticky-total" dangerouslySetInnerHTML={{ __html: starHTML }}></div>

    </div>;

  }
  useEffect(() => {
    window.jQuery(".lag-nav-fade-header").removeClass("active");
    if (searchActive) {
      searchInputRef.current.focus();
    } else {
      setSearchActive(false);
    }
  }, [searchActive]);
  useEffect(() => {
    getReviewSummary(setReviewSummary);
  }, []);
  useEffect(() => {
    getReviewSchemaInfo(page, setServiceType, setAreaServed);
  }, [page]);

  //MenuItems data start
  const [menuItems, setMenuItems] = useState([])
  let countApiCalls = 0
  const loadMenuItems = (whichHeader, loadParent = false) => {
    countApiCalls++
    if (countApiCalls > 5) return false
    axios.get(`${APIS.CUSTOM_MEGA_MENU}?countryLang=${whichHeader}`).then((res) => {
      if (res.data?.status === 1) {
        const data = res.data?.mega_menu ? res.data?.mega_menu : []
        setMenuItems(data);
      }
    }).catch(() => {
      // loadMenuItems('/', true)
    })
  }
  useEffect(() => {
    const path = window.location.pathname.split('/');
    let whichHeader = '/';
  
    // Function to check if conditions are met
    const checkConditions = () => {
      if (window.innerWidth > 767 || document.body.classList.contains('nav-is-toggled')) {
        if (path.length >= 2) {
          whichHeader = path[1];
          loadMenuItems(whichHeader, true);
        } else {
          loadMenuItems(whichHeader);
        }
      }
    };
  
    // Initial check
    checkConditions();
  
    // Event listener for window resize
    const handleResize = () => {
      checkConditions();
    };
  
    // Event listener for 'nav-is-toggled' class change
    const handleClassChange = () => {
      checkConditions();
      // Disconnect the observer after the initial check
      observer.disconnect();
    };
  
    window.addEventListener('resize', handleResize);
    
    // MutationObserver to monitor class changes
    const observer = new MutationObserver(handleClassChange);
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
  
    // Cleanup function to remove event listeners and observer
    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  //MenuItems data end
  return ((ReviewSummary) ? (
    <><div><div class={`search-blcok active-hight ${searchActive ? 'active' : ''}`}>
      <div class="b-search">
        <input type="text" class="form-control form-search" id="SearchText" title="Search Text" onKeyDown={handleKeyPress} ref={searchInputRef} />
        <a class="btn-b-search" title="Search" onClick={handleSearchClick}><img src="/packimpex/images/NL_icons/search.svg" alt="" /></a>
        <button class="b-close" title="Close" onClick={hiddenClick} type="button"><img src="/packimpex/images/NL_icons/Close.png" alt="" /></button>
      </div>
    </div>
      <div class={`search-modal cover-modal ${searchActive ? 'active show-modal' : ''}`} onClick={hiddenClick}></div>
    </div>
      <header className="sticky-header" id="top">
        <TopBar />
        <div className="start-sticky">
          <div className="start-sticky-wrap">
            <label>
              {ReviewSummary ? (
                <><a href="/testimonials" target="_self">
                  {renderStars(ReviewSummary.Summary, ReviewSummary.Average)}

                </a><div dangerouslySetInnerHTML={{
                  __html: `
                  <script type="application/ld+json">
                  {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "Relocation Support Service",
                    "description": "Support to manage the process of relocation including arranging necessary documents (visa, long-term stay permissions), finding a new house (accommodation), finding a school for children (education), finding a job for the partner, arranging a teacher for the family (language training), and introducing expatriates to the local culture.",
                    "url": "https://www.packimpex.com/home",
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "${ReviewSummary.Average}",
                      "bestRating": "5",
                      "ratingCount": "${ReviewSummary.Summary}"
                    }
                  }
                </script>
              
          `
                }} /></>
              ) : null
              }
            </label>
          </div>
        </div>
        {/* <div className="header-container"> */}
        <MenuItems setSearchActive={setSearchActive} menuItems={menuItems}></MenuItems>
        {/* </div> */}
      </header></>) : <div style={{ height: "140px" }}></div>
  );
};

export default Menu;
