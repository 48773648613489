import React, { useState, useEffect } from "react";
import styles from "../C21_Card/C21_Card.module.css";
import { getC21CardListsData } from "../../api/getData";
import { COMPONENT_C21_CARD_COMPONENT } from "../../constants/componentTypes";

export default function Card({ componentID, resourceVersionId, currentCountryCode }) {
    const [C21CardListsData, setC21CardListsData] = useState(null);
    const [ImgUrl, setImgUrl] = useState(null);
    useEffect(() => {
        getC21CardListsData(COMPONENT_C21_CARD_COMPONENT, componentID, setC21CardListsData, resourceVersionId, currentCountryCode, setImgUrl);
    }, [componentID, currentCountryCode, resourceVersionId]);
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            {
                C21CardListsData?.attributes && (
                    <>
                        <div className={`${styles.top_card_b} col-lg-4 col-md-6 col-sm-6 col-xs-12`} onClick={handleClick}>
                            <a class="read-more" data-index="1">
                                <div className={`${styles.card_block}`}>
                                    <div className={`${styles.card_image}`} >
                                        <img src={ImgUrl} width="700" height="1000" alt={C21CardListsData?.attributes?.field_name} />
                                        {C21CardListsData?.attributes?.field_button_text && (
                                            <div className={`${styles.card_caption}`}>
                                                <div className={`${styles.card_top_block}`}>
                                                    <div className={`${styles.btn_b} btn left`} >
                                                        {C21CardListsData?.attributes?.field_button_text}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        }

                                        <div className={`${styles.txt_block}`}>
                                            <h3>{C21CardListsData?.attributes?.field_name}</h3>
                                            <p>
                                                {C21CardListsData?.attributes?.field_position}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {
                            isOpen && C21CardListsData?.attributes?.field_description?.value && (
                                <div className={`${styles.history_content} ab-cont`} >
                                    <div className={`${styles.pop_layer}`} onClick={handleClick}></div>
                                    <div className={`${styles.modal_dialog_centered} ${styles.modal_dialog}`}>
                                        <div className={`${styles.modal_content}`} >
                                            <button type="button" className={`${styles.close} close01`} data-block="1" onClick={handleClick}>
                                                <span aria-hidden="true" >×</span>
                                            </button>
                                            <div className={`${styles.modal_body}`} dangerouslySetInnerHTML={{
                                                __html: C21CardListsData?.attributes?.field_description?.value,
                                            }} />
                                            <div className={`${styles.team_member_social}`}>
                                                <a target="_blank" href={C21CardListsData?.attributes?.field_linkedin_url?.uri} class="team-member-icon" rel="noreferrer">
                                                    <span className={`${styles.icon} icon-linkedin`}></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </>
    );
};
