import React, { useState, useEffect } from "react";
import styles from "../C21_Card/C21_Card.module.css";
import { getC21CardListsData } from "../../api/getData";
import { COMPONENT_C21_CARD_COMPONENT } from "../../constants/componentTypes";
import Card from './Card';


export default function C21_CardLists({ componentID, resourceVersionId, currentCountryCode }) {
  const [C21CardListsData, setC21CardListsData] = useState(null);

  useEffect(() => {
    getC21CardListsData(COMPONENT_C21_CARD_COMPONENT, componentID, setC21CardListsData, resourceVersionId, currentCountryCode);
  }, [componentID, currentCountryCode, resourceVersionId]);

  return (
    <>
      {
        C21CardListsData?.attributes.status && (
          <>
            <div className={`${styles.common_content_w} ${styles.card_margin} row`} aria-labelledby="home-tab" role="tabpanel">
              {C21CardListsData.relationships.field_card_lists &&
                C21CardListsData.relationships.field_card_lists.data.map((data, index) => (
                  <Card componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                ))}
            </div>
          </>
        )
      }
    </>
  );
};
